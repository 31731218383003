import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import brand from '../../../assets/images/logo-icon-blk.png';
// import ArrowIcon from '../../../assets/images/right-arrow.png';
import BoostedIconRight from '../../../assets/images/next-btn-right-elect.png';
import BoostedIconLeft from '../../../assets/images/next-btn-left-elect.png';
import ProfileIcon from '../../../assets/images/profile-img-standard.png';
import EditIcon from '../../../assets/images/edit-icon.png';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { deleteSeekerProfile as deleteSeekerProfileApi, 
  deletePosterProfile as deletePosterProfileApi } from "../../../api/users";
import Alert from "sweetalert2";
import {
  API_ADD_DOCUMNET_JOBSEEKER,
  API_ADD_IMAGE_DOCUMNET_JOBSEEKER,
  API_CURRENT_USER,
  API_GET_SEEKER_DETAIL,
  API_PERSONAL_INFO,
  API_SUBMIT_JOBSEEKER_DATA,
  API_UPDATE_ACTIVE_STATUS,
  API_UPDATE_ACTIVE_STATUS_FOR_JOB,
  API_SET_POINTS,
  postApi,
  postApiPoster,
  // SEEKER_DELETE_JOB,
} from '../../../utils';
import HeaderMenu from '../../../components/HeaderMenu/headerMenu';
import JobRoleUpdate from './updateModal/JobRoleUpdate';
import JobTitleUpdate from './updateModal/JobTitleUpdate';
import IndustryUpdate from './updateModal/IndustryUpdate';
import EducationUpdate from './updateModal/EducationUpdate';
import SkillLevelUpdate from './updateModal/SkillLevelUpdate';
import LocationUpdate from './updateModal/LocationUpdate';
import CompanySizeUpdate from './updateModal/CompanySizeUpdate';
import MinPayUpdate from './updateModal/MinPayUpdate';
import HardSkillUpdate1 from './updateModal/HardSkillUpdate1';
import SoftSkillUpdate1 from './updateModal/SoftSkillUpdate1';
// import VideoUpdate from './updateModal/VideoUpdate';
import EmploymentTypeUpdateModal from './updateModal/EmploymentTypeUpdate';

import { useDispatch } from "react-redux/es/exports";
import { handelUserLogout } from '../../../redux/actions';
import { downloadUserCsv as downloadUserCsvApi } from '../../../api/users';

function Profile() {

const { state } = useLocation();
const navigation = useNavigate();
const dispatch = useDispatch();

const baseCsvDownloadCsvText = 'Download CV'
const userToken = useSelector((state) => state.UserAuth.userData.payload.token);
const userType = useSelector((state) => state.userType.userType);
const userId = useSelector((state) => state.UserAuth.userData.payload.user._id);
const [isLoading, setLoading] = useState(false);
const [jobRole, setJobRole] = useState("");
const [jobTitle, setJobTitle] = useState("");
const [desireIndustry, setDesireIndustry] = useState("");
const [eduLevel, setEduLevel] = useState("");
const [skillLevel, setSkillLevel] = useState("");
const [location, setLocation] = useState("");
const [companySize, setCompanySize] = useState("");
const [employmentType, setEmploymentType] = useState("");
const [expectedPay, setExpectedPay] = useState("");
const [softSkill, setSoftSkill] = useState("");
const [hardSkill, setHardSkill] = useState("");
const [userImage, setUserImage] = useState("");
const [personalBio, setPersonalBio] = useState("");
const [keywords, setKeywords] = useState([]);
const [image, setImage] = useState(null);
const [video, setVideo] = useState(null);
const [openVideo, setOpenVideo] = useState(false);

const [personInfo, setPersonInfo] = useState([]);

const [about, setAbout] = useState(personalBio);
const [editAbout, setEditAbout] = useState(false);

const [jobRoleModal, setJobRoleModal] = useState(false);
const [jobTitleModal, setJobTitleModal] = useState(false);
const [industryModal, setIndustryModal] = useState(false);
const [educationModal, setEducationModal] = useState(false);
const [skillLevelModal, setSkillLevelModal] = useState(false);
const [locationModal, setLocationModal] = useState(false);
const [companySizeModal, setCompanySizeModal] = useState(false);
const [employmentTypeModal, setEmploymentTypeModal] = useState(false);
const [minPayModal, setMinPayModal] = useState(false);
const [hard1Modal, setHard1Modal] = useState(false);
const [hard2Modal, setHard2Modal] = useState(false);
const [soft1Modal, setSoft1Modal] = useState(false);
const [soft2Modal, setSoft2Modal] = useState(false);
const [prevSoftSkill, setPrevSoftSkill] = useState([]);
const [prevHardSkill, setPrevHardSkill] = useState([]);
const [prevCV, setPrevCV] = useState("");

const [userData, setUserData] = useState({});

const [fileName, setFileName] = useState("");
const [documentSelected, setDocumentSelected] = useState("");
const [isUploadDocument, setUploadDocument] = useState(false);
const [active, setActive] = useState(personInfo?.status);

const [csvDownloadCsvText, setCsvDownloadCsvText] = useState(baseCsvDownloadCsvText)
//******Watch the current Index changes

useEffect(() => {
  requestDataFromServer();
  requestDataFromServer1();
}, []);

useEffect(() => {
  if (documentSelected) {
    setUploadDocument(true);
  }
}, [fileName, documentSelected]);

const requestDataFromServer = () => {
  setLoading(true);
  const obj = {
    usertype: 1,
    userId: userId,
  };
  postApi(
    API_GET_SEEKER_DETAIL,
    obj,
    userToken,
    successFunction,
    errorFunction
  );
};

const [seekerJobAssociateId, setseekerJobAssociateId] = useState("");

const handleStatusChange = (isChecked) => {
  // console.log("isChecked", isChecked);
  postApi(
    API_UPDATE_ACTIVE_STATUS_FOR_JOB,
    { jobId: seekerJobAssociateId, status: isChecked },
    userToken,
    statusUpdated,
    statusError
  );
};
const [jobStatus, setjobStatus] = useState(true);

const successFunction = (response) => {
  setLoading(false);
  if (response.status === 200) {
    const result = response?.data?.result[0];
    setUserData(result);
    if (result) {
      // Creating the result object
      const resultData = {
        seekerJobAssociateId: result?._id,
        personalBio: result?.personalbio,
        companySize: result?.campanySize,
        expectedPay: result?.employmentMode,
        employmentType: result?.employmentType,
        eduLevel: result?.education[0]?.option,
        desireIndustry: result?.industry[0]?.option,
        jobTitle: result?.jobtitle[0]?.option,
        jobRole: result?.role[0]?.option,
        skillLevel: result?.skilllevel[0]?.option,
        location: `${result?.locationName ?? ''}`,
        jobStatus: result?.status,
        keywords: result?.feelsuits1,
        softSkill: result?.softSkill.map((item) => ` ${item.skill.option} (${item.skill.exp})`).toString(),
        hardSkill: result?.hardSkill.map((item) => ` ${item.skill.option} (${item.skill.exp})`).toString(),
        prevSoftSkill: result?.softSkill,
        prevHardSkill: result?.hardSkill,
      };
      console.log('resultData: ', resultData)
      setseekerJobAssociateId(resultData.seekerJobAssociateId);
      setPersonalBio(resultData.personalBio);
      setCompanySize(resultData.companySize);
      setExpectedPay(resultData.expectedPay);
      setEmploymentType(resultData.employmentType);
      setEduLevel(resultData.eduLevel);
      setDesireIndustry(resultData.desireIndustry);
      setJobTitle(resultData.jobTitle);
      setJobRole(resultData.jobRole);
      setSkillLevel(resultData.skillLevel);
      setLocation(resultData.location);
      setjobStatus(resultData.jobStatus);
      setKeywords(resultData.keywords);
      setSoftSkill(resultData.softSkill);
      setHardSkill(resultData.hardSkill);
      setPrevSoftSkill(resultData.prevSoftSkill);
      setPrevHardSkill(resultData.prevHardSkill);
    }
  }
};

const handleLogout = () => {
  dispatch(handelUserLogout());
  navigation("/");
};

const errorFunction = (error) => {
  setLoading(false);
};

const requestDataFromServer1 = () => {
  // setLoading(true);
  const obj = {
    usertype: 1,
    _id: userId,
  };
  postApi(API_CURRENT_USER, obj, userToken, successFunction1, errorFunction1);
};

const successFunction1 = (res) => {
  // console.log(res?.data?.data, 'PERSON INFO--------------------');
  // console.log("PERSONAL DATA", res?.data?.data);
  setPersonInfo(res?.data?.data);
  setImage(res?.data?.data?.imagePath ?? res?.data?.data?.imagePath ?? ProfileIcon);
  setVideo(res?.data?.data?.videoPath);
  setPrevCV(res?.data?.data?.documentPath);
};

const errorFunction1 = (errr) => {
  console.log(errr, "erorr");
};

const updatedSuccesfully = (response) => {
  const obj = {
    userId: userId,
    id: seekerJobAssociateId,
  };
  if (response.status === 200) {
    if (userType.payload === "jobSeeker") {
      postApi(API_SET_POINTS, obj, userToken, () => {}, () => {});
    } else {
      postApiPoster(API_SET_POINTS, obj, userToken, () => {}, () => {});
    }
    setLoading(false);
    requestDataFromServer();
  } else {
    setLoading(false);
  }
};

const errorWhileUpdate = (response) => {
  // console.log("errorWhileUpdate::::::", response);
};

const updateProfileFunc = (field, value, field2, value2) => {
  setLoading(true);
  postApi(API_SUBMIT_JOBSEEKER_DATA, { ...userData, [field]: value, [field2]: value2 },
    userToken, updatedSuccesfully, errorWhileUpdate);
};

const getFileSelected = (e) => {
  const fileName = e.target.files[0].name;
  const fileSelected = e.target.files[0];
  setFileName(fileName);
  setDocumentSelected(fileSelected);
};

const documentUploaded = (res) => {
  // console.log("DOCUMENT_UPLOADED", res);
  const obj = {
    userId: userId,
    id: seekerJobAssociateId,
  };
  if (userType.payload === "jobSeeker") {
    postApi(API_SET_POINTS, obj, userToken, () => {}, () => {});
  } else {
    postApiPoster(API_SET_POINTS, obj, userToken, () => {}, () => {});
  }
  setLoading(false);
  setTimeout(() => {
    requestDataFromServer();
  }, 1000);
  toast("CV Uploaded Successfully", {
    type: "success",
  });
};

const documentUploadError = (error) => {
  toast("CV Upload Error", {type: "error"});
};

const handleUploadDocument = () => {
  var formdata = new FormData();
  formdata.append("userId", userId);
  formdata.append("document", documentSelected);

  postApi(API_ADD_DOCUMNET_JOBSEEKER, formdata, userToken, documentUploaded, documentUploadError);
};

const statusUpdated = (res) => {
  setFileName("");
  toast("User Status Updated", {type: "success"});
};

const statusError = (error) => {
  toast("User Status Not Updated", {type: "error"});
};


//delete job
const deleteJob = async () => {
  Alert.fire({
    title: "Are you sure?",
    text: "You want to delete your account",
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      deleteProfile();
    }
  });
};

const deleteProfile = () => {
  const type = userType.payload
  if(type === 'jobSeeker'){
    deleteSeeker()
  }else{
    deletePoster()
  }
};

function deleteSeeker(){
  deleteSeekerProfileApi(userId).then((_resp) => {
    if(_resp.data.result === 'success'){
      handleLogout()
    } else {
        // console.log(_resp.data);
    }
  }).catch((_resp) => {
      console.error(_resp);
  });
}

function deletePoster(){
  deletePosterProfileApi(userId).then((_resp) => {
    if(_resp.data.result === 'success'){
      handleLogout()
    }else{
      // console.log(_resp.data)
    }
  }).catch((_resp) => {
    console.error(_resp)
  })
}

function onDownloadCsvClick(_link) {
  setCsvDownloadCsvText('Downloading...');
  downloadUserCsvApi(_link).then((_resp) => {
    const fileName = _link.split('/').pop();
    const fileBlob = new Blob([_resp.data]);
    

    // Create a URL for the Blob and trigger the download
    const url = window.URL.createObjectURL(fileBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }).catch((_resp) => {
      console.error(_resp);
  }).finally(() => {
      setCsvDownloadCsvText(baseCsvDownloadCsvText);
  });
}

return (
  <React.Fragment>
    <HeaderMenu />
    {isLoading && (
      <div className="d-flex justify-content-center align-items-center LoaderCenter">
        {" "}
        <div className="block">
          <span
            className="spinner-grow spinner-grow-lg mx-2"
            role="status"
            aria-hidden="true"
          />
          <h6 className="LoaderText">Processing...</h6>
        </div>
      </div>
    )}
    {!isLoading && (
      <section id="dashboard-cnt-area">
        <div className="container">
          <div className="row dashboard-cnt-row">
            <div className="col-lg-9 col-sm-8 col-xs-12 mircroshoft-cnt">
              <div className="row">
                <div className={"col-lg-12"}>
                  <div className="match-card">
                    <div className="match-card-content">
                      <div className="match-card-image">
                        <div className={'match-card-image-container'}>
                          <img src={image} alt="Profile picture"/>
                          {video && (
                              <div className={'video-profile'} onClick={() => setOpenVideo(!openVideo)}>
                                <i className="fa fa-play text-light" style={{paddingLeft: '2px'}}/>
                              </div>
                          )}
                        </div>
                      </div>
                      <div className="match-card-info">
                        <div className="match-name text-capitalize">{personInfo.fullName}</div>
                        <div>{personInfo.location}</div>
                      </div>
                      <div className="match-card-percent" style={{justifyContent: 'center'}}>
                        <label className="steps-checkbox" style={{ float: "right" }}>
                          <span>Active</span>
                          <input onChange={(e) => handleStatusChange(e.target.checked)} defaultChecked={jobStatus} type="checkbox"/>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mt-4">
                  <h5>Personal Bio</h5>
                  {editAbout ? (
                    <textarea type="text" value={personalBio} onChange={(e) => setAbout(e.target.value)} style={{ width: "100%", minHeight: "100px" }}/>
                  ) : (
                    <p>{personalBio}</p>
                  )}
                </div>
                <div className="col-lg-4 mt-4">
                  <h5>
                    Desired Culture
                    <span onClick={() => setJobRoleModal(true)}>
                      <img src={EditIcon} style={{ height: "20px", objectFit: "contain", cursor: 'pointer' }}/>
                    </span>
                  </h5>
                  <p className={"capitalize"}>{jobRole}</p>
                  <JobRoleUpdate
                    jobRole={jobRole}
                    jobRoleModal={jobRoleModal}
                    setJobRoleModal={setJobRoleModal}
                    updateProfileFunc={updateProfileFunc}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <h5>
                    Desired Job title
                    <span onClick={() => setJobTitleModal(true)}>
                      <img src={EditIcon} style={{ height: "20px", objectFit: "contain", cursor: 'pointer' }}/>
                    </span>
                  </h5>
                  <p className={"capitalize"}>{jobTitle}</p>
                  <JobTitleUpdate
                    prevJobTitle={jobTitle}
                    jobTitleModal={jobTitleModal}
                    setJobTitleModal={setJobTitleModal}
                    updateProfileFunc={updateProfileFunc}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <h5>
                    Desired industry
                    <span onClick={() => setIndustryModal(true)}>
                      <img src={EditIcon} style={{ height: "20px", objectFit: "contain", cursor: 'pointer' }}/>
                    </span>
                  </h5>
                  <p className={"capitalize"}>{desireIndustry}</p>
                  <IndustryUpdate
                    prevIndustry={desireIndustry}
                    industryModal={industryModal}
                    setIndustryModal={setIndustryModal}
                    updateProfileFunc={updateProfileFunc}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <h5>
                    Education levels
                    <span onClick={() => setEducationModal(true)}>
                      <img src={EditIcon} style={{ height: "20px", objectFit: "contain", cursor: 'pointer' }} />
                    </span>
                  </h5>
                  <p className={"capitalize"}>{eduLevel}</p>
                  <EducationUpdate
                    prevEducation={eduLevel}
                    educationModal={educationModal}
                    setEducationModal={setEducationModal}
                    updateProfileFunc={updateProfileFunc}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <h5>
                    Skill level
                    <span onClick={() => setSkillLevelModal(true)}>
                      <img src={EditIcon} style={{ height: "20px", objectFit: "contain", cursor: 'pointer' }} />
                    </span>
                  </h5>
                  <p className={"capitalize"}>{skillLevel}</p>
                  <SkillLevelUpdate
                    prevSkillLevel={skillLevel}
                    skillLevelModal={skillLevelModal}
                    setSkillLevelModal={setSkillLevelModal}
                    updateProfileFunc={updateProfileFunc}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <h5>
                    Location
                    <span onClick={() => setLocationModal(true)}>
                      <img src={EditIcon} style={{ height: "20px", objectFit: "contain", cursor: 'pointer' }}/>
                    </span>
                  </h5>
                  <p className={"capitalize"}>{location}</p>
                  <LocationUpdate
                    prevLocation={location}
                    locationModal={locationModal}
                    setLocationModal={setLocationModal}
                    updateProfileFunc={updateProfileFunc}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <h5>
                    Prefered Company size
                    <span onClick={() => setCompanySizeModal(true)}>
                      <img src={EditIcon} style={{ height: "20px", objectFit: "contain", cursor: 'pointer' }}/>
                    </span>
                  </h5>
                  <p className={"capitalize"}>{companySize}</p>
                </div>
                <CompanySizeUpdate
                  prevCompanySize={companySize}
                  companySizeModal={companySizeModal}
                  setCompanySizeModal={setCompanySizeModal}
                  updateProfileFunc={updateProfileFunc}
                />
                <div className="col-lg-4 mt-4">
                  <h5>
                    Salary
                    <span onClick={() => setMinPayModal(true)}>
                      <img src={EditIcon}  style={{ height: "20px", objectFit: "contain", cursor: 'pointer' }}/>
                    </span>
                  </h5>
                  <p className={"capitalize"}>{expectedPay}</p>
                  <MinPayUpdate
                    prevMinPay={expectedPay}
                    minPayModal={minPayModal}
                    setMinPayModal={setMinPayModal}
                    updateProfileFunc={updateProfileFunc}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <h5>
                    Employment Type
                    <span onClick={() => setEmploymentTypeModal(true)}>
                      <img src={EditIcon} style={{ height: "20px", objectFit: "contain", cursor: 'pointer' }}/>
                    </span>
                  </h5>
                  <p className={"capitalize"}>{employmentType}</p>

                  <EmploymentTypeUpdateModal
                    prevMinPay={expectedPay}
                    employmentTypeModal={employmentTypeModal}
                    prevEmploymentType={employmentType}
                    setEmploymentTypeModal={setEmploymentTypeModal}
                    updateProfileFunc={updateProfileFunc}
                  />
                </div>

                <div className="col-lg-4 mt-4">
                  <h5>
                    Soft skills
                    <span onClick={() => setSoft1Modal(true)}>
                      <img src={EditIcon} style={{ height: "20px", objectFit: "contain", cursor: 'pointer' }}/>
                    </span>
                  </h5>
                  <p className={"capitalize"}>{softSkill}</p>
                  <SoftSkillUpdate1
                    prevSoftSkill={prevSoftSkill}
                    soft1Modal={soft1Modal}
                    setSoft1Modal={setSoft1Modal}
                    updateProfileFunc={updateProfileFunc}
                  />
                </div>
                <div className="col-lg-4 mt-4">
                  <h5>
                    Hard skills
                    <span onClick={() => setHard1Modal(true)}>
                      <img src={EditIcon} style={{ height: "20px", objectFit: "contain", cursor: 'pointer' }}/>
                    </span>
                  </h5>
                  <p className={"capitalize"}>{hardSkill}</p>
                  <HardSkillUpdate1
                    prevHardSkill={prevHardSkill}
                    hard1Modal={hard1Modal}
                    setHard1Modal={setHard1Modal}
                    updateProfileFunc={updateProfileFunc}
                  />
                </div>
                <div className="col-lg-12 mt-2">
                  <h5>Keyword</h5>

                  <p className={"capitalize"}>
                    {keywords.map((data) => {
                      return `${data} `;
                    })}
                  </p>
                </div>

                <div className="col-lg-4 mt-4 text-left">
                  <h5>CV Upload</h5>
                  <div className="form-group upload-cv-col" style={{ padding: 0, margin: 0, maxWidth: 300 }}>
                    <div className="input-group">
                      <div className="custom-file">
                        <div>
                          <p>{fileName ? fileName : "Update CV"}</p>
                          <input type="file" accept=".doc, .docx,.ppt, .pptx,.pdf" className="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" onChange={(e) => getFileSelected(e)}/>
                        </div>
                        <label className="custom-file-label"  htmlFor="inputGroupFile01">
                          <span className="hidden-xs">Browse</span>
                        </label>
                      </div>

                      {fileName && (
                        <button onClick={handleUploadDocument} className="form-btn mt-4">
                          Upload
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className={"col-lg-12 mt-4"}>
                  <div className="match-card">
                    <div className="match-card-content" style={{display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: "space-evenly"}}>

                      {prevCV && (
                          <div className="enhance-section">
                            {/* <a href={prevCV}> */}
                              <button onClick={() => onDownloadCsvClick(prevCV)} className="form-btn" style={{margin: '0', fontSize: '20px', minHeight: '57px', height: 'auto'}} disabled={csvDownloadCsvText !== baseCsvDownloadCsvText}>{csvDownloadCsvText}</button>
                            {/* </a> */}
                          </div>
                      )}

                      <div className="enhance-section">
                        {personInfo?.isPaid ? (
                            <div className="form-btn btn-warning"
                                style={{ background: "#ffe923", margin: '0', fontSize: '20px', minHeight: '57px', height: 'auto', display: "flex", alignItems: "center", justifyContent: "center" }}>
                              <img src={BoostedIconLeft} style={{height: "50px"}} />Profile Boosted<img src={BoostedIconRight} style={{height: "50px"}} />
                            </div>
                        ) : (
                            <button
                                className="form-btn videoUploadBtn btn-warning"
                                style={{ background: "#ffe923", margin: '0', fontSize: '20px', minHeight: '57px', height: 'auto' }}
                                onClick={() => navigation("../boostProfile")}
                            >
                              Boost profile
                            </button>
                        )}
                      </div>
                      <button
                          className="form-btn btn-warning"
                          style={{ background: "#ff0e0e", margin: '0', fontSize: '20px', minHeight: '57px', height: 'auto' }}
                          onClick={() => {
                            deleteJob();
                          }}
                      >
                        Delete Account
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )}
  </React.Fragment>
);
}

export default Profile;
